
import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'

const IndexPage = () => {
    return (
        <Layout lang="es">

            <SEO
                title="Optiwe - Sistema de CRM para WhatsApp e Inmobiliarias "
                description="Conecta a todos los equipos de tu inmobiliaria al CRM #1 para WhatsApp y mejora la comunicación entre los clientes y tu empresa."
                lang="es"
                locales={
                    [
                        { hreflang: 'es', href: 'https://optiwe.com/es/soluciones/inmobiliarias/' }
                    ]
                }
            />

            <main className="container-fluid ow-banner">
                {
                    /*
                    ^*********
                    * HEADER *
                    **********
                    */
                }
                <Nav lang="es" showButtons />

                {
                    /*
                    ***************
                    * MAIN BANNER *
                    ***************
                    * TODO: If the banner is used in another view, create a <Banner /> component
                    */
                }
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                            <h1 className="ow-section--cards__title">
                                <span className="bold">Gestión de clientes por WhatsApp para <strong>INMOBILIARIAS</strong></span>
                            </h1>
                            <p className="ow-section--cards__desc">
                                Durante transacciones de alquiler o compra/venta de propiedades, las inmobiliarias intercambian muchos mensajes con potenciales clientes en diferentes medios digitales como email, WhatsApp, Instagram, Facebook, página web y más. Este tipo de transacciones suelen ser decisiones importantes que no son tomadas a la ligera y para los clientes, en ese intercambio de mensajes, es importante encontrar una empresa que le genere confianza, apoye y guíe en su búsqueda.
                            </p>
                            <p className="ow-section--cards__desc">
                                Incorpora el chatbot de Optiwe en WhatsApp para tu inmobiliaria y establece una mejor comunicación con tus clientes a partir de ofrecer una experiencia 24x7, que incluye respuestas instantáneas a preguntas frecuentes, búsqueda ágil de propiedades y el acceso a toda la información relevante en tiempo real, sin largas esperas de que algún agente de atención al cliente esté disponible.
                            </p>
                            <p className="ow-section--cards__desc">
                                El charbot de Optiwe también te permite mejorar la calidad de tu base de datos, personalizando la conversación, recogiendo y digitalizando, de un modo orgánico, la información que te permitirá identificar a los clientes potenciales y definir su perfil. Dejá a un lado la necesidad de formularios que puedan resultar agobiantes. Conecta los chatbots a tu CRM y ahorrá tiempo administrativo de tus vendedores para que puedan dedicarlo a tareas de mayor prioridad, cómo mostrar propiedades.
                            </p>
                            <p className="ow-section--cards__desc">
                                Ofrece la posibilidad de coordinar visitas directamente desde WhatsApp, Facebook, Instagram o tu sitio web, los canales habituales que utilizan los interesados.
                            </p>
                            <p className="ow-section--cards__desc">
                                Potencia tus anuncios redirigiendo a tus clientes a whatsapp  y haz que el  bot responda con toda la información relevante sobre las propiedades de su interés 24x7.
                            </p>
                            <p className="ow-section--cards__desc">
                                Con nuestra App Multiagente, permite a tus vendedores gestionar su cartera de clientes desde el móvil, en cualquier lugar que se encuentren. Ten todo el historial de conversaciones en una única plataforma, sin importar el canal de comunicación y accesible a todo el equipo, para dar un mejor seguimiento.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage
